import React from "react";
import { PMGrid, PMIcon, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";

export interface DetailCheckedWidgetProps {
    text?: string,
    textColor?: string,
    icon?: string
    direction?: string
    data: Object
    fields: Array<any>
}

/* Example Field data*/
// const fields = [
//     {label: "First Name", key: "first_name" },
//     {label: "Last Name", key: "last_name"},
//     {label: "Phone", key: "phone"},
//     {label: "Email", key: "email"}
// ]

export const DetailCheckedWidget = (props: BaseWidgetProps & DetailCheckedWidgetProps) => {
    return (
        <BaseWidget {...props}>
            <PMGrid container direction={props.direction || "column"}
                columnSpacing={4}
                rowSpacing={2}
                justifyContent="space-between"
                alignItems="flex-start">
                {
                    props.fields.map((field, index) => {
                        return (
                            <PMGrid key={index} direction="row" display="flex" justifyContent="center" alignItems="center">
                                    
                                    <PMText sx={{fontSize:16}} variant="caption">{field.label+":"}</PMText>
                                    {props.fields[index].data?.map((item, idx) => {
                                        return (<React.Fragment key={idx}><PMText sx={{fontSize:16, mx:1}} variant="caption">{item.name}</PMText><PMIcon icon={item?.icon} color="info"></PMIcon></React.Fragment>)
                                    
                                        
                                    })}
                                    
                            </PMGrid>)
                    })
                }
            </PMGrid>
        </BaseWidget>
    )
}

DetailCheckedWidget.defaultProps = {
    direction: "column"
}

export default DetailCheckedWidget