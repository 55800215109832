import { PMTable, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";
import { PMTableProps } from "../../pmcomponents/pmtable/PMTable";
import { CardWidget, CardWidgetProps } from "../CardWidget";
export interface TableWidgetProps extends BaseWidgetProps {
    cardResolution?: number;
}

export const TableWidget = (props: TableWidgetProps & PMTableProps & CardWidgetProps) => {
  const { rows, columns, tableParams, tableApi, ...baseProps } = props;

  console.log(props.cardResolution,"resolutoi")
  return (
    <>
      <BaseWidget {...baseProps} bodyPadding={0}>
        {window.innerWidth <= props.cardResolution ? (
          <CardWidget  {...props} />
        ) : (
          <PMTable {...props} />
        )}
      </BaseWidget>
    </>
  );
};

TableWidget.defaultProps = {};

export default TableWidget;
