import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      headers.set("authorization", "Bearer " + token);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "loginApiTag",
    "signupApiTag",
    "forgotPasswordTag",
    "resetPasswordTag",
    "uploadPolicyTag",
    "parsedPolicyListTag",
    "parsedPolicyDetailsTag",
    "parsedPolicyUpdateTag",
    "renewalThisMonthTag",
    "upcomingRenewalSevenDaysTag",
    "todayActivityRenewalsTag",
    "policyRenewalsPendingListTag",
    "policyRenewalsDoneListTag",
    "policyRenewalsMissedListTag",
    "markAsFailedTag",
    "policyRenewalsListTag",
    "pospFetchTag",
    "insurerFetchTag",
    "policyListTag",
    "policyExportTag",
    "policyDateWiseTag",
    "policyInsurerWiseTag",
    "policyProductWiseTag",
    "policyExtractedDateWiseTag",
    "policyAllStatsCardTag",
    "policyMissedStatsCardTag",
    "policyDoneStatsCardTag",
    "policyOfThisMonthTag",
    "policyPendingStatsCardTag",
    "renewalPolicyDetailTag",
    "policyDetailsTag",
    "policyRenewalsListFilterTag",
    "renewalDetailsTag",
    "policyDetailTag",
    "pospListAutocompleteTag",
    "policyCreateTag",
    "policyUpdateTag",
    "monthlyRenewalsChangeTag",
  ],
  endpoints: (builder) => ({
    loginApi: builder.mutation({
      query: (data) => ({
        url: "https://policy-management.demo.permute.in/auth/api/v1/login",
        method: "POST",
        body: data,
      }),
    }),
    signupApi: builder.mutation({
      query: (data) => ({
        url: "https://policy-management.demo.permute.in/auth/api/v1/signup",
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "https://policy-management.demo.permute.in/auth/api/v1/reset-password/request",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "https://policy-management.demo.permute.in/auth/api/v1/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    uploadPolicy: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/request-parsing",
        method: "POST",
        body: data,
      }),
    }),
    parsedPolicyList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["parsedPolicyListTag"],
    }),
    parsedPolicyDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["parsedPolicyDetailsTag"],
    }),
    parsedPolicyUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    renewalThisMonth: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalThisMonthTag"],
    }),
    upcomingRenewalSevenDays: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["upcomingRenewalSevenDaysTag"],
    }),
    todayActivityRenewals: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["todayActivityRenewalsTag"],
    }),
    policyRenewalsPendingList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsPendingListTag"],
    }),
    policyRenewalsDoneList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsDoneListTag"],
    }),
    policyRenewalsMissedList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsMissedListTag"],
    }),
    markAsFailed: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    policyRenewalsList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsListTag"],
    }),
    pospFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospFetchTag"],
    }),
    insurerFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/insurer/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["insurerFetchTag"],
    }),
    policyList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyListTag"],
    }),
    policyExport: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/export",
        method: "POST",
        body: data,
      }),
    }),
    policyDateWise: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDateWiseTag"],
    }),
    policyInsurerWise: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyInsurerWiseTag"],
    }),
    policyProductWise: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyProductWiseTag"],
    }),
    policyExtractedDateWise: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyExtractedDateWiseTag"],
    }),
    policyAllStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/all",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyAllStatsCardTag"],
    }),
    policyMissedStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/missed",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyMissedStatsCardTag"],
    }),
    policyDoneStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/done",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDoneStatsCardTag"],
    }),
    policyOfThisMonth: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyOfThisMonthTag"],
    }),
    policyPendingStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/pending",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyPendingStatsCardTag"],
    }),
    renewalPolicyDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalPolicyDetailTag"],
    }),
    policyDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDetailsTag"],
    }),
    policyRenewalsListFilter: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsListFilterTag"],
    }),
    renewalDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalDetailsTag"],
    }),
    policyDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDetailTag"],
    }),
    pospListAutocomplete: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospListAutocompleteTag"],
    }),
    policyCreate: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/create",
        method: "POST",
        body: data,
      }),
    }),
    policyUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    monthlyRenewalsChange: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/monthly-change",
        params: data,
        method: "GET",
      }),
      providesTags: ["monthlyRenewalsChangeTag"],
    }),
  }),
});

export const {
  useLoginApiMutation,
  useSignupApiMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUploadPolicyMutation,
  useParsedPolicyListQuery,
  useParsedPolicyDetailsQuery,
  useParsedPolicyUpdateMutation,
  useRenewalThisMonthQuery,
  useUpcomingRenewalSevenDaysQuery,
  useTodayActivityRenewalsQuery,
  usePolicyRenewalsPendingListQuery,
  usePolicyRenewalsDoneListQuery,
  usePolicyRenewalsMissedListQuery,
  useMarkAsFailedMutation,
  usePolicyRenewalsListQuery,
  usePospFetchQuery,
  useInsurerFetchQuery,
  usePolicyListQuery,
  usePolicyExportMutation,
  usePolicyDateWiseQuery,
  usePolicyInsurerWiseQuery,
  usePolicyProductWiseQuery,
  usePolicyExtractedDateWiseQuery,
  usePolicyAllStatsCardQuery,
  usePolicyMissedStatsCardQuery,
  usePolicyDoneStatsCardQuery,
  usePolicyOfThisMonthQuery,
  usePolicyPendingStatsCardQuery,
  useRenewalPolicyDetailQuery,
  usePolicyDetailsQuery,
  usePolicyRenewalsListFilterQuery,
  useRenewalDetailsQuery,
  usePolicyDetailQuery,
  usePospListAutocompleteQuery,
  usePolicyCreateMutation,
  usePolicyUpdateMutation,
  useMonthlyRenewalsChangeQuery,
} = apiSlice;
