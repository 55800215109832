// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMCard, Image, FormWidget, PMIcon, PMText } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useForgotPasswordMutation } from "store/apiSlice";
import { default as forgotPasswordFormatter } from "transformations/forgotPasswordFormatter";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function ForgotPassword() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [forgotPasswordMutationTrigger, forgotPasswordMutationResult] = useForgotPasswordMutation();
    const [submited, setsubmited] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "" }));
    }, []);

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Email Sent", { variant: "success" });
    };

    const forgotPassword = async (data) => {
        var apiData = forgotPasswordFormatter(data);

        if (apiData) {
            forgotPasswordMutationTrigger({ ...apiData })
                .unwrap()
                .then((data) => {
                    formSubmited(data);
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const loginForm = (data) => {
        forgotPassword(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image image="https://www.permutehq.com/assets/images/logo/logo.svg" md={6}></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12} justifyContent="center">
                                {!submited ? (
                                    <FormWidget
                                        direction="column"
                                        header="Enter Email to reset Password"
                                        Onsubmit={loginForm}
                                        fieldsets={[
                                            {
                                                direction: "column",
                                                fields: [
                                                    {
                                                        label: "Email",
                                                        name: "useremail",
                                                        type: "email",
                                                        required: true,
                                                        fullWidth: true,
                                                    },
                                                ],
                                            },
                                        ]}
                                    ></FormWidget>
                                ) : (
                                    ""
                                )}
                                {submited ? (
                                    <PMCard>
                                        <PMGrid
                                            item={true}
                                            xs={12}
                                            container={true}
                                            sx={{ "margin-left": 0, p: 2 }}
                                            justifyContent="center"
                                        >
                                            <PMIcon icon="task_alt" color="success" fontSize="large"></PMIcon>

                                            <PMText children="Please check your email for reset password link"></PMText>
                                        </PMGrid>
                                    </PMCard>
                                ) : (
                                    ""
                                )}
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default ForgotPassword;
