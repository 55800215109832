import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import "./PMFormatterText.scss";
import { PropsWithChildren } from "react";
import { PMText } from "..";
import { Tooltip } from "@mui/material";
import moment from "moment";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";
import Button from "@mui/material/Button";
export interface PMFormatterTextProps extends TypographyProps {
  value?: any;
  formatType?: string;
  variant2?: string;
}

const locales = "en-IN"

const currencyFormatter = new Intl.NumberFormat(locales,{
    style: "currency",
    currency: "INR",
    notation: "compact",
    maximumFractionDigits:2,
});

const currencyFormatterToolTip = new Intl.NumberFormat(locales, {
  style: "currency",
  currency: "INR",
});

const PMFormatterText = (props: PropsWithChildren<PMFormatterTextProps>) => {
  const { formatType, value, variant2 } = props;

  return (
    <>
      {formatType === "percentage" ? (
        <PMText variant={variant2 as Variant}>{value + "%"}</PMText>
      ) : formatType === "currency" ? (
        <>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={currencyFormatterToolTip.format(value)}
          >
            <Button sx={{bgcolor:'transparent',color:'black',padding:0}}>
              <PMText variant={variant2 as Variant}>
               { currencyFormatter.format(value).replace("T","K").replace(/.{1}$/,' $&') }    
              </PMText>
            </Button>
          </Tooltip>
        </>
      ) : formatType === "date" ? (
        <PMText variant={variant2 as Variant}>
          {moment(value).format("DD-MMM-YYYY")}
        </PMText>
      ) : (
        <PMText variant={variant2 as Variant}>{value}</PMText>
      )}
    </>
  );
};

export default PMFormatterText;
