import { ArrowLeft, ArrowRight, Download, Edit } from "@mui/icons-material";
import { Box, Chip, Link, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { PMGrid, PMText } from "../../pmcomponents";
import { BaseWidgetProps } from "../BaseWidget";

export interface CardWidgetProps {
  text?: string;
  textColor?: string;
  icon?: string;
  data: Object;
  fields: Array<any>;
  columns: Array<any>;
  columnSpacing?: number;
  rowSpacing?: number;
  tableApi: any;
  tableParams: any[];
}

const locale = "en-IN";
const currencyFormatter = new Intl.NumberFormat(locale, {
  style: "currency",
  currency: "INR",
});

export const CardWidget = (props: BaseWidgetProps & CardWidgetProps) => {
  const {
    columns,
    columnSpacing,
    rowSpacing,
    tableApi,
    tableParams,
    ...baseProps
  } = props;

  const [numberOfData, setNumberOfData] = useState(5);
  const [perPageData, setPerPageData] = useState(numberOfData);
  const [initialData, setIntialData] = useState(0);
  const [pages, setPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(tableApi?.size);
  const [value, setValue] = useState(numberOfData);

  var [tableParamsGet, tableParamsSet] = useState({ size: 50, page: 1 });

  if (tableParams) {
    [tableParamsGet, tableParamsSet] = tableParams;
  }

  const page = tableParamsGet?.page || 1;
  const pageSize = tableParamsGet?.size || 50;

  useEffect(() => {
    setRowsPerPage(tableApi?.size || 0);
  }, []);

  const handleBack = (e) => {
    if (initialData <= 0) {
      return;
    }
    setIntialData(initialData - numberOfData);
    setPerPageData(initialData);
  };

  const handleForward = () => {
    if (perPageData > tableApi?.items?.length) {
      return;
    }
    setIntialData(perPageData);
    setPerPageData(perPageData + numberOfData);
  };
  // Client Side Pagination Function End

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log(newPage, "Page Number");
    setPages(newPage);
    tableParamsSet({
      size: pageSize,
      page: ++newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPages(0);
    tableParamsSet({
      size: parseInt(event.target.value),
      page: page,
    });
  };

  const handleRowData = (event) => {
    setValue(event.target.value);
    setPerPageData(event.target.value);
    setNumberOfData(event.target.value);
  };

  return (
    <Box {...baseProps}>
      {!tableApi
        ? tableApi?.items?.slice(initialData, perPageData).map((ele, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 5,
                  mx: "auto",
                  padding: 2,
                  paddingY: 4,
                  borderRadius: 2,
                  boxShadow: 3,
                }}
                key={index}
              >
                {props.columns.map((field, index) => {
                  return (
                    <PMGrid
                      key={index}
                      xs={field.md ? field.md : 6}
                      sm={field.md ? field.md : 4}
                      sx={{ alignSelf: "start" }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <PMText variant="caption">{field.headerName}</PMText>
                        {field?.type === "img" ? (
                          <>
                            {" "}
                            <a href={field.field} title="description">
                              click here
                            </a>{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {field?.type === "currency" ? (
                          <PMText variant="body1">
                            {currencyFormatter.format(
                              ele[field.field?.split(".")[0]][
                                field.field?.split(".")[1]
                              ] || 0
                            )}
                          </PMText>
                        ) : (
                          ""
                        )}

                        {field?.type === "download" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                            }}
                          >
                            <Link
                              href={ele[field?.field]}
                              target="_blank"
                              download
                              underline="hover"
                            >
                              {ele[field?.field] ? <Download /> : ""}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}

                        {field?.type === "chip" ? (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Chip
                              label={ele[field?.field]}
                              variant="outlined"
                              color={
                                ele[field?.field] === "DONE"
                                  ? "success"
                                  : ele[field?.field] === "PENDING"
                                  ? "warning"
                                  : "secondary"
                              }
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "edit" ? (
                          <Box sx={{ marginTop: 0.5 }}>
                            <Link
                              href={field?.url + "/" + ele["id"]}
                              color="blue"
                              sx={{
                                "white-space": "break-spaces",
                                "text-align": "center",
                                fontSize: 15,
                              }}
                              underline="hover"
                            >
                              {field.headerName ? field.headerName : <Edit />}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "button" ? (
                          <Box
                            sx={{
                              marginTop: 0.5,
                              bgcolor: "#42a5f5",
                              px: 2,
                              py: 1,
                              borderRadius: 1,
                              boxShadow: 1,
                            }}
                          >
                            <Link
                              href={field?.url + "/" + ele["id"]}
                              color="white"
                              sx={{
                                "text-align": "center",
                                fontWeight: 600,
                                fontSize: 15,
                              }}
                              underline="hover"
                            >
                              {field.headerName ? field.headerName : <Edit />}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "date" ? (
                          <PMText variant="body1">
                            {field?.field
                              ? format(
                                  parseISO(ele[field?.field]),
                                  "dd-MMM-yyyy"
                                )
                              : "--"}
                          </PMText>
                        ) : (
                          ""
                        )}
                        {field?.type === "datetime" ? (
                          <PMText variant="body1">
                            {field?.field
                              ? format(
                                  parseISO(ele[field?.field]),
                                  "dd-MMM-yyyy h:m a"
                                )
                              : "--"}
                          </PMText>
                        ) : (
                          ""
                        )}
                        {field?.type ? (
                          ""
                        ) : (
                          <>
                            <PMText variant="body1">
                              {field.field?.split(".").length >= 2
                                ? ele[field.field?.split(".")?.[0]]?.[
                                    field.field?.split(".")[1]
                                  ] || ""
                                : ele[field.field] || ""}
                            </PMText>
                            {field?.subtext ||
                            (props?.data && props?.data[field?.subkey]) ? (
                              <PMText variant="body2">
                                {field.subtext
                                  ? field.subtext
                                  : props?.data[field?.subkey]}
                              </PMText>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </Stack>
                    </PMGrid>
                  );
                })}
              </Box>
            );
          })
        : tableApi.items?.map((ele, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  rowGap: 2,
                  mx: "auto",
                  marginTop: 1,
                  padding: 2,
                  paddingY: 4,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
                key={index}
              >
                {props.columns.map((field, index) => {
                  return (
                    <PMGrid
                      key={index}
                      xs={field.md ? field.md : 6}
                      sm={field.md ? field.md : 4}
                      sx={{ alignSelf: "start" }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <PMText variant="caption">{field.headerName}</PMText>
                        {field?.type === "img" ? (
                          <>
                            {" "}
                            <a href={field.field} title="description">
                              click here
                            </a>{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {field?.type === "currency" ? (
                          <PMText variant="body1">
                            {currencyFormatter.format(
                              ele[field.field?.split(".")[0]][
                                field.field?.split(".")[1]
                              ] || 0
                            )}
                          </PMText>
                        ) : (
                          ""
                        )}

                        {field?.type === "download" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                            }}
                          >
                            <Link
                              href={ele[field?.field]}
                              target="_blank"
                              download
                              underline="hover"
                            >
                              {ele[field?.field] ? <Download /> : ""}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}

                        {field?.type === "chip" ? (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Chip
                              label={ele[field?.field]}
                              variant="outlined"
                              color={
                                ele[field?.field] === "DONE"
                                  ? "success"
                                  : ele[field?.field] === "PENDING"
                                  ? "warning"
                                  : "secondary"
                              }
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "edit" ? (
                          <Box sx={{ marginTop: 0.5 }}>
                            <Link
                              href={field?.url + "/" + ele["id"]}
                              color="blue"
                              sx={{
                                "white-space": "break-spaces",
                                "text-align": "center",
                                fontSize: 15,
                              }}
                              underline="hover"
                            >
                              {field.headerName ? field.headerName : <Edit />}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "button" ? (
                          <Box
                            sx={{
                              marginTop: 0.5,
                              bgcolor: "#42a5f5",
                              px: 2,
                              py: 1,
                              borderRadius: 1,
                              boxShadow: 1,
                            }}
                          >
                            <Link
                              href={field?.url + "/" + ele["id"]}
                              color="white"
                              sx={{
                                "text-align": "center",
                                fontWeight: 600,
                                fontSize: 15,
                              }}
                              underline="hover"
                            >
                              {field.headerName ? field.headerName : <Edit />}
                            </Link>
                          </Box>
                        ) : (
                          ""
                        )}
                        {field?.type === "date" ? (
                          <PMText variant="body1">
                            {field?.field
                              ? format(
                                  parseISO(ele[field?.field]),
                                  "dd-MMM-yyyy"
                                )
                              : "--"}
                          </PMText>
                        ) : (
                          ""
                        )}
                        {field?.type === "datetime" ? (
                          <PMText variant="body1">
                            {field?.field
                              ? format(
                                  parseISO(ele[field?.field]),
                                  "dd-MMM-yyyy h:m a"
                                )
                              : "--"}
                          </PMText>
                        ) : (
                          ""
                        )}
                        {field?.type ? (
                          ""
                        ) : (
                          <>
                            <PMText variant="body1">
                              {field.field?.split(".").length >= 2
                                ? ele[field.field?.split(".")?.[0]]?.[
                                    field.field?.split(".")[1]
                                  ] || ""
                                : ele[field.field] || ""}
                            </PMText>
                            {field?.subtext ||
                            (props?.data && props?.data[field?.subkey]) ? (
                              <PMText variant="body2">
                                {field.subtext
                                  ? field.subtext
                                  : props?.data[field?.subkey]}
                              </PMText>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </Stack>
                    </PMGrid>
                  );
                })}
              </Box>
            );
          })}

      {!tableApi ? (
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            padding: 2,
            display: "flex",
            justifyContent: "end",
            alignItems: "self-start",
            cursor: "pointer",
          }}
        >
          <Typography sx={{ fontSize: 14, mt: 1, mr: 1 }}>
            Rows per page:{" "}
          </Typography>
          <Select
            labelId="rows_select_label"
            id="rows_select"
            defaultValue={value}
            value={value}
            onChange={handleRowData}
            sx={{ mt: 1, mr: 3, height: 30 }}
          >
            <MenuItem value={numberOfData}>{numberOfData}</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Typography sx={{ fontSize: 14, mt: 1, mr: 3 }}>
            {initialData + 1}-
            {tableApi?.total < perPageData ? tableApi?.total : perPageData} of{" "}
            {tableApi?.total}
          </Typography>
          <Box onClick={handleBack} sx={{ mt: 1 }}>
            <ArrowLeft fontSize="small" />
          </Box>

          <Box onClick={handleForward} sx={{ mt: 1 }}>
            <ArrowRight fontSize="small" />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            padding: 2,
            display: "flex",
            justifyContent: "end",
            alignItems: "self-start",
            cursor: "pointer",
          }}
        >
          <TablePagination
            component="div"
            count={tableApi?.total || 0}
            page={pages}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage || tableApi?.size || 0}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

CardWidget.defaultProps = {
  columns: 4,
  columnSpacing: 1,
  rowSpacing: 3,
};

export default CardWidget;
