// @ts-nocheck

import { NotesWidget, PMGrid, PMText } from "lib";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
function Help() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "" }));
    }, []);

    return (
        <div className="builder_wrapper">
            <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                <PMGrid sx={{ marginTop: 2 }}>
                    <PMText varaint="h5" sx={{ opacity: 0.5 }} color="info" children="Policy Inwarding"></PMText>
                </PMGrid>

                <NotesWidget
                    header="Create Policy"
                    content={[
                        "1. Select Policy Inwarding from the navigation bar.",
                        "2. Click on 'Create Policy'.",
                        "3. Fill in all the required data to create an insurance policy. Also, Upload the insurance policy.",
                        "4. Click 'Submit'. (If an error occurs, please ensure that all mandatory fields are filled in correctly.)",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="View Policy"
                    content={[
                        "1. The submitted policy will be created and can be viewed in the View Policy Section.",
                        "2. Click on 'View Policy' in the Policy Inwarding section.",
                        "3. In the Insurance Policy list, click on 'View' to display the data for the selected policy on the View Policy page.",
                        "4. Click the download button in the insurance policy list to download the uploaded policy.",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Extract Policy"
                    content={[
                        "1. If the user needs to extract any insurance policy, follow these steps:",
                        "(a). Click on the 'Extract Policy' section.",
                        "(b). Click 'Upload' and select the policy PDF from your computer.",
                        "(c). Once the policy is selected, click 'Extract' to extract the policy.",
                        "2. When the PDF is extracted, the Edit page with the extracted data will be displayed.",
                        "(a). If any changes are required, the user can edit the data.",
                        "(b). If all the extracted data is correct, the user can simply submit the page.",
                        "(c). The extracted policy can be viewed on the “View Policy” page.",
                    ]}
                ></NotesWidget>

            </PMGrid>
        </div>
    );
}
export default Help;
