// @ts-nocheck

import { Outlet, PMMenu } from "lib";

import { useNavigate } from "react-router-dom";
function NavigationMenu() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const history = useNavigate();
    page.data.menuItems = [
        { type: "Subheader", text: "Demo System" },
        { type: "Divider" },
        {
            type: "Nested",
            text: "New Policy",
            icon: { icon: "insert_drive_file", text_color: "info", size: "large" },
            collapseItem: [
                {
                    type: "Collapse",
                    text: "Create Policy",
                    url: "/policy-create",
                    icon: { icon: "note_add", text_color: "info", size: "large" },
                },
                {
                    type: "Collapse",
                    text: "View Policy",
                    url: "/policy-list",
                    icon: { icon: "note_alt", text_color: "info", size: "large" },
                },
                {
                    type: "Collapse",
                    text: "Extract Policy",
                    url: "/extract-policy",
                    icon: { icon: "note_add", text_color: "info", size: "large" },
                },
            ],
        },
        
        { type: "Divider" },
        { text: "Help", url: "/help", icon: { icon: "help", text_color: "info", size: "large" } },
        { type: "Divider" },
    ];

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <PMMenu
                open={true}
                title=""
                logo="https://www.permutehq.com/assets/images/logo/logo.svg"
                onClick={MenuHomeCLick}
                menuItems={page.data.menuItems}
            >
                <Outlet></Outlet>
            </PMMenu>
        </div>
    );
}
export default NavigationMenu;
