// @ts-nocheck
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import jwt_decode from "jwt-decode";
import { ApiLoaderWidget } from "lib";
import { SnackbarProvider } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { mergeStore } from "store/appSlice";
import AuthRoute from "./authComponent/AuthRoute";
import {
    ForgotPassword,
    Help,
    Login,
    NavigationMenu,
    PageNotFound,
    PermissionDenied,
    PolicyCreate,
    PolicyEdit,
    PolicyList,
    ResetPassword,
    Signup,
    UploadPolicy
} from "./pages";
import { theme } from "./theme";
function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userProfile = localStorage.getItem("userProfile");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userProfileStore = useSelector((states) => states?.appStore?.userProfile);

    if (userProfileStore && !userProfile) {
        localStorage.setItem("userProfile", userProfileStore || "");
    } else if (!userProfileStore && userProfile) {
        var formatedData = {
            userProfile: userProfile || "",
        };
        dispatch(mergeStore(formatedData));
    }

    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };

        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;
    if (authToken) {
        var decoded = jwt_decode(authToken);
        if (Date.now() > decoded.exp * 1000) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ApiLoaderWidget></ApiLoaderWidget>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Routes>
                        <Route path="/" element={<Login />}></Route>

                        <Route path="/forgot-password" element={<ForgotPassword />}></Route>

                        <Route path="/signup" element={<Signup />}></Route>

                        <Route path="/reset-password/:id" element={<ResetPassword />}></Route>

                        <Route path="/permission-denied" element={<PermissionDenied />}></Route>

                        <Route path="*" element={<PageNotFound />}></Route>

                        <Route element={<NavigationMenu />}>
                        

                            <Route
                                path="/policy-create"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyCreate />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/extract-policy"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <UploadPolicy />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-edit/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyEdit />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-list"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/help"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <Help />
                                    </AuthRoute>
                                }
                            ></Route>
                        </Route>
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
