// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMCard, Image, FormWidget, PMButton } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useSignupApiMutation } from "store/apiSlice";
import { useNavigate } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function Signup() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [signupApiMutationTrigger, signupApiMutationResult] = useSignupApiMutation();
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "" }));
    }, []);

    const login = (data) => {
        history("/login/");
    };

    const afterSignup = (data) => {
        history("/login");
    };

    const signupApi = async (data) => {
        var apiData = data;

        if (apiData) {
            signupApiMutationTrigger({ ...apiData })
                .unwrap()
                .then((data) => {
                    afterSignup(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const loginForm = (data) => {
        signupApi(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image image="https://www.permutehq.com/assets/images/logo/logo.svg" md={6}></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12}>
                                <FormWidget
                                    direction="column"
                                    header="Enter Email and password to login"
                                    Onsubmit={loginForm}
                                    fieldsets={[
                                        {
                                            direction: "column",
                                            fields: [
                                                {
                                                    label: "Username",
                                                    name: "username",
                                                    type: "text",
                                                    required: true,
                                                    fullWidth: true,
                                                    validation: {
                                                        minLength: 4,
                                                        maxLength: 16,
                                                        pattern: "\\/^[a-zA-Z0-9]+$/",
                                                    },
                                                },
                                                {
                                                    label: "Email Address",
                                                    name: "email",
                                                    type: "email",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                                {
                                                    label: "Phone Number",
                                                    name: "number",
                                                    type: "text",
                                                    required: true,
                                                    fullWidth: true,
                                                    validation: { minLength: 10, maxLength: 10, pattern: "^\\d+$" },
                                                },
                                                {
                                                    label: "Password",
                                                    name: "password",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                    validation: { minLength: 4, maxLength: 16 },
                                                },
                                            ],
                                        },
                                    ]}
                                ></FormWidget>

                                <PMGrid container={true} sx={{ "margin-top": 10 }} justifyContent="flex-end" xs={12}>
                                    <PMButton label="Sign In" sx={{ "align-self": "right" }} onClick={login}></PMButton>
                                </PMGrid>
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default Signup;
